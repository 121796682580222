.App {
  text-align: center;
}

.App-logo {
  height: 80px;
}

.App-header {
  background-color: white;
  height: 150px;
  padding: 20px;
  color: black;
}
.App-logo1 {

  padding-right: 20px;
    height: 40px;
  }
  
.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
